import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Press = () => (
  <Layout>
    <SEO title="Press" />
    <div class="banner">
      <div class="container">
        <div class="colx1">
          <div class="copy">
            <h3 class="center">
              Cravv<sup>®</sup> in the News
            </h3>
            <a class="btn_alt" href="#"><i class="fa fa-angle-down"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="colx2">
          <div class="card">
            <h6 class="subline">
            May 2018
            </h6>
            <h5 class="left">
            Cytisine for smoking cessation
            </h5>
            <a class="btn_alt" href="http://www.cmaj.ca/content/190/19/E596" target="_blank" rel="noopener noreferrer">Read More <i className="fa fa-angle-right"></i></a>
          </div>
        </div>
        <div class="colx2">
          <div class="card">
            <h6 class="subline">
            October 2017
            </h6>
            <h5 class="left">
            Centre for Addiction and Mental Health hosts webinar on cytisine
            </h5>
            <a class="btn_alt" href="http://camh.adobeconnect.com/pchfouxiyw6q/" target="_blank" rel="noopener noreferrer">Read More <i className="fa fa-angle-right"></i></a>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="colx2">
          <div class="card">
            <h6 class="subline">
            August 2017
            </h6>
            <h5 class="left">
            Waterloo pharmacist brings new nicotine addiction treatment to Canada
            </h5>
            <a class="btn_alt" href="https://www.waterloochronicle.ca/community-story/7500791-waterloo-pharmacist-brings-new-nicotine-addiction-treatment-to-canada/" target="_blank" rel="noopener noreferrer">Read More <i className="fa fa-angle-right"></i></a>
          </div>        
        </div>
        <div class="colx2">
          <div class="card">
            <h6 class="subline">
            August 2017
            </h6>
            <h5 class="left">
            New over-the-counter natural product promises to make quitting smoking easier for millions of Canadians
            </h5>
            <a class="btn_alt" href="http://globenewswire.ca/news-release/2017/08/07/1081288/0/en/Now-available-new-over-the-counter-natural-product-promises-to-make-quitting-smoking-easier-for-millions-of-Canadians.html" target="_blank" rel="noopener noreferrer">Read More <i className="fa fa-angle-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Press
